<template>
  <div id="app">
    <router-view :empresa="empresa" />
  </div>
</template>

<script>
import Api from "@/components/Api";
export default {
  name: "App",
  data() {
    return {
      esMovil: Api.esMovil(),
      empresa: {
        nombre: "ELITE",
        nombreLargo: "ELITE Abogados",
        logo: "../elite/SVG/logo.svg",
        iso: "../elite/SVG/icono.svg",
        isogris: "../elite/SVG/icono.svg",
        completo: "../elite/SVG/completo.svg",
        icono_negro: "../elite/SVG/icono_negro.svg",
        icono_blanco: "../elite/SVG/icono_blanco.svg",
        slogan: {
          es: "Firma de abogados",
          en: "Lawers firm",
        },
        email: "contacto@elite-abogados.mx",
        direccion:
          "Av. Chapultepec 1922 Nivel 8 oficina B8, Desarrollos del Pedregal, 78295 SLP ",
        telefono: "444 180 8193",
      },
      lang: localStorage.getItem("lang") || "es",
    };
  },
  async mounted() {
    Api.guardarLocal("elite-abogados.mx.empresa", this.empresa);
    let vm = this;
    console.log("BDLG página montada", this.lang, this.esMovil);
  },
  methods: {
    esHome() {
      return (
        this.$route.path.indexOf("/home") >= 0 ||
        this.$route.path.indexOf("/areas") >= 0 ||
        this.$route.path.indexOf("/abogados") >= 0
      );
    },
  },
  watch: {
    "$route.path": function () {
      if (this.$route.path != "/")
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 200);
    },
  },
};
</script>
