import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "LandingPage",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/components/Landing"),
    },
    {
      path: "/home",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/components/Landing"),
    },
    {
      path: "*",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/components/404"),
    },
    {
      path: "/abogados",
      name: "Abogados",
      component: () =>
        import(/* webpackChunkName: "abogados" */ "@/components/Abogados"),
    },
    {
      path: "/abogados/:id",
      name: "Abogado",
      component: () =>
        import(/* webpackChunkName: "abogados" */ "@/components/Abogado"),
      props: true,
    },
    {
      path: "/areas",
      name: "Areas",
      component: () =>
        import(/* webpackChunkName: "areas" */ "@/components/AreasHandler"),
    },
    {
      path: "/areas/:area",
      name: "Area",
      component: () =>
        import(/* webpackChunkName: "areas" */ "@/components/AreasHandler"),
      props: true,
    },
    {
      path: "/blog",
      name: "Blog",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/components/Blog"),
    },
    {
      path: "/blog/:id",
      name: "Post",
      component: () =>
        import(/* webpackChunkName: "post" */ "@/components/Post"),
      props: true,
    },
    {
      path: "/post/:slug",
      name: "Post",
      component: () =>
        import(/* webpackChunkName: "post" */ "@/components/Post"),
      props: true,
    },
    {
      path: "/nosotros",
      name: "Nosotros",
      component: () =>
        import(/* webpackChunkName: "post" */ "@/components/NosotrosHandler"),
    },
    {
      path: "/nosotros",
      name: "NosotrosLang",
      component: () =>
        import(/* webpackChunkName: "post" */ "@/components/NosotrosHandler"),
    },
    {
      path: "/nosotros/:seccion",
      name: "NosotrosSeccion",
      component: () =>
        import(/* webpackChunkName: "post" */ "@/components/NosotrosHandler"),
      props: true,
    },
    {
      path: "/privacidad",
      name: "Privacidad",
      component: () =>
        import(/* webpackChunkName: "privacidad" */ "@/components/Privacidad"),
    },
  ],
});
